import { sendRequestTyped } from '@app/services/opta/request';
import { getFeedData } from '@app/services/opta/feedService';
import { getTeamNamesData } from '@app/services/kentico/teamsService';
import { FeedTypes } from '@app/services/opta/constants/feedTypes';
import { OptaSDMilanTeamIdMap } from '@app/services/opta/constants/teamConstants';
import { MatchResult } from '@app/types/matchTypes';
import { mapMatchResults, mapSDMatchResults } from '@app/helpers/matchHelpers';
import { LanguageType } from '@app/types/localizationTypes';

interface RequestParamsProps {
  gameId?: string;
  optaId: string;
  seasonId: string;
  useFilter?: boolean;
  language?: LanguageType;
}

/** MEN Match Results use Classic OPTA API and are built in 4 steps:
 * 1. get opta F26 feed - live competition matches data. THIS IS 1-st PRIORITY DATA
 * 2. get opta F1 feed - season competition matches data. THIS IS 2-nd PRIORITY DATA
 * 3. get kentico Team Names data and apply on feed's Teams data
 * */
export const getMatchResultsData = (requestParams: RequestParamsProps): Promise<MatchResult[]> => {
  const {
    seasonId, optaId, useFilter = true, language,
  } = requestParams;

  const liveData = getFeedData({
    path: 'competition.php',
    feedType: FeedTypes.live,
    competition: optaId,
    seasonId,
  });

  const staticData = getFeedData({
    path: 'competition.php',
    feedType: FeedTypes.schedule,
    competition: optaId,
    seasonId,
  });

  const teamNamesData = getTeamNamesData(language);

  return Promise
    .all([liveData, staticData, teamNamesData])
    .then((data) => mapMatchResults(data, { seasonId, optaId, useFilter }));
};

interface SDRequestParamsProps extends RequestParamsProps {
  id: string;
}

export const getSDMatchResultsData = ({
  id, seasonId, optaId, language,
}: SDRequestParamsProps): Promise<MatchResult[]> => {
  const results = sendRequestTyped({
    path: 'soccerdata/match',
    isOptaSD: true,
    params: {
      _rt: 'b',
      _fmt: 'json',
      _pgSz: '1000',
      live: 'yes',
      status: 'all',
      tmcl: seasonId,
      comp: optaId,
      ctst: OptaSDMilanTeamIdMap[id],
    },
  });

  const teamNamesData = getTeamNamesData(language, false);

  return Promise
    .all([results, teamNamesData])
    .then((data) => mapSDMatchResults(data, { seasonId, optaId }));
};
