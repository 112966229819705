import { TeamSubMenuId, Image, Seo } from '@app/types/configurationTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { GoogleAdsProps } from '@app/types/googleTypes';

export enum PlayerPositionFilter {
  All = 'squadre_primasquadramaschile_all',
  Goalkeepers = 'squadre_primasquadramaschile_portieri',
  Defenders = 'squadre_primasquadramaschile_difensori',
  Midfielders = 'squadre_primasquadramaschile_centrocampisti',
  Forwards = 'squadre_primasquadramaschile_attaccanti',
  Staff = 'squadre_primasquadramaschile_staff',
  Coaches = 'squadre_primasquadramaschile_allenatori',

  AllWomen = 'squadre_primasquadrafemminile_all',
  GoalkeepersWomen = 'squadre_primasquadrafemminile_portieri',
  DefendersWomen = 'squadre_primasquadrafemminile_difensori',
  MidfieldersWomen = 'squadre_primasquadrafemminile_centrocampisti',
  ForwardsWomen = 'squadre_primasquadrafemminile_attaccanti',
  StaffWomen = 'squadre_primasquadrafemminile_staff',
  CoachesWomen = 'squadre_primasquadrafemminile_allenatori',

  AllPrimavera = 'squadre_primavera_all',
  GoalkeepersPrimavera = 'squadre_primavera_portieri',
  DefendersPrimavera = 'squadre_primavera_difensori',
  MidfieldersPrimavera = 'squadre_primavera_centrocampisti',
  ForwardsPrimavera = 'squadre_primavera_attaccanti',
  StaffPrimavera = 'squadre_primavera_staff',
  CoachesPrimavera = 'squadre_primavera_allenatori',

  AllMilanFuturo = 'squadre_milanfuturo_all',
  GoalkeepersMilanFuturo = 'squadre_milanfuturo_portieri',
  DefendersMilanFuturo = 'squadre_milanfuturo_difensori',
  MidfieldersMilanFuturo = 'squadre_milanfuturo_centrocampisti',
  ForwardsMilanFuturo = 'squadre_milanfuturo_attaccanti',
  StaffMilanFuturo = 'squadre_milanfuturo_staff',
  CoachesMilanFuturo = 'squadre_milanfuturo_allenatori',

}

export enum PlayerPosition {
  Goalkeeper = 'goalkeeper',
  Defender = 'defender',
  Midfielder = 'midfielder',
  Forward = 'forward',
  Staff = 'staff',
  Coach = 'coach'
}

export interface Player extends Seo {
  id: string;
  kenticoId: string;
  firstName: string;
  lastName: string;
  number: number;
  position: PlayerPosition;
  positionName: string;
  photoLarge: Image;
  storeUrl: string;
  birthDate: string;
  birthPlace: string;
  nationality: string;
  height: string;
  weight: string;
  preferredFoot: string;
  joinDate: string;
  biography: string;
  urlSlug: string;
  hide: boolean;
}

export interface Coach extends Seo {
  id: string;//
  kenticoId: string;//
  firstName: string;//
  lastName: string;//
  position: PlayerPosition;//
  photoLarge: Image;//
  birthDate: string;//
  birthPlace: string;//
  nationality: string;//
  coachSince: string;//
  joinDate: string;//
  biographyTitle: string;//
  biography: string;//
  careerTitle: string;//
  career: string;//
  hide: boolean;//
}

export type Team = (Player | Coach)[];

export type PlayersWithAds = (Player|Coach|GoogleAdsProps)[];

export type LocalizedTeam = {
  [key in LanguageType]?: Team;
}

export interface SetTeamPayload {
  type: TeamSubMenuId;
  players: LocalizedTeam;
}

export const getPositionByFilter = (filter: PlayerPositionFilter): PlayerPosition | null => {
  switch (filter) {
    // Goalkeeper
    case PlayerPositionFilter.Goalkeepers:
    case PlayerPositionFilter.GoalkeepersWomen:
    case PlayerPositionFilter.GoalkeepersPrimavera:
    case PlayerPositionFilter.GoalkeepersMilanFuturo:
      return PlayerPosition.Goalkeeper;
    // Defender
    case PlayerPositionFilter.Defenders:
    case PlayerPositionFilter.DefendersWomen:
    case PlayerPositionFilter.DefendersPrimavera:
    case PlayerPositionFilter.DefendersMilanFuturo:
      return PlayerPosition.Defender;
    // Midfielder
    case PlayerPositionFilter.Forwards:
    case PlayerPositionFilter.ForwardsWomen:
    case PlayerPositionFilter.ForwardsPrimavera:
    case PlayerPositionFilter.ForwardsMilanFuturo:
      return PlayerPosition.Forward;
    // Midfielder
    case PlayerPositionFilter.Midfielders:
    case PlayerPositionFilter.MidfieldersWomen:
    case PlayerPositionFilter.MidfieldersPrimavera:
    case PlayerPositionFilter.MidfieldersMilanFuturo:
      return PlayerPosition.Midfielder;
    // STUFF
    case PlayerPositionFilter.Staff:
    case PlayerPositionFilter.StaffWomen:
    case PlayerPositionFilter.StaffPrimavera:
    case PlayerPositionFilter.StaffMilanFuturo:
      return PlayerPosition.Staff;
    // COACHES
    case PlayerPositionFilter.Coaches:
    case PlayerPositionFilter.CoachesWomen:
    case PlayerPositionFilter.CoachesPrimavera:
    case PlayerPositionFilter.CoachesMilanFuturo:
      return PlayerPosition.Coach;
    // default
    default:
      return null;
  }
};

export interface TeamNames {
  [teamId: string]: {
    teamName: string;
    shortTeamName: string;
  };
}

export interface TeamStatistics {
  gamesPlayed: number;
  players: { [playerId: string]: PlayerStatistics };
}

export interface PlayerStatistics {
  position?: PlayerPosition;
  playerId: string;
  gamesPlayed: number;
  timePlayed: number;
  yellowCards: number;
  redCards: number;
  duelsWon: number;
  totalPasses: number;
  successfulShortPasses: number;
  unsuccessfulShortPasses: number;
  successfulLongPasses: number;
  unsuccessfulLongPasses: number;
  totalSuccessfulPasses: number;
  successfulLaunches: number;
}

export interface GoalkeeperStatistics extends PlayerStatistics{
  position: PlayerPosition.Goalkeeper;
  cleanSheets: number;
  goalsConceded: number;
  savesMade: number;
  punches: number;
  catches: number;
}

export interface DefenderStatistics extends PlayerStatistics{
  position: PlayerPosition.Defender;
  recoveries: number;
  totalTackles: number;
  blocks: number;
  totalClearances: number;
  totalFoulsConceded: number;
  totalFoulsWon: number;
}

export interface MidfielderStatistics extends PlayerStatistics{
  position: PlayerPosition.Midfielder;
  totalShots: number;
  shotsOnTarget: number;
  shotsOffTarget: number;
  blockedShots: number;
  // additional
  recoveries: number;
  totalTackles: number;
  totalFoulsConceded: number;
  totalFoulsWon: number;
}

export interface ForwardStatistics extends PlayerStatistics{
  position: PlayerPosition.Forward;
  goals: number;
  rightFootGoals: number;
  leftFootGoals: number;
  headedGoals: number;
  goalsFromInsideBox: number;
  goalsFromOutsideBox: number;
  totalShots: number;
  shotsOnTarget: number;
  shotsOffTarget: number;
  blockedShots: number;
}

export type LocalizedPlayer = {
  [key in LanguageType]: Player | undefined
}
